import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import {
  Flex,
  Box,
  Text,
  Image
} from 'rebass'

import Hide from 'hidden-styled'

import Container from './container'
import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <Box bg='white'>
    <Container>
      <Flex style={{
      }} alignItems='center' bg='white' py={4} px={2}>
        <Box px={[2, 3]}>
          <Text fontSize={[3, 5]} fontWeight='bold'>
            <Link
              to="/"
              style={{
                color: 'black',
                textDecoration: 'none',
                display: 'inline-block'
              }}
            >
              <Hide md lg>
                Reversity
              </Hide>
              <Hide xs sm>
                <Image style={{ display: 'inline-block' }} width={[18, 25]} mr={2} src={logo} alt='Reversity logo' />
                Reversity
              </Hide>
            </Link>
          </Text>
        </Box>
        <Box mx='auto'></Box>
        <Box px={[1, 3]}>
          <Link
            to='/plans'
            style={{
              color: 'black',
              textDecoration: 'none'
            }}
            activeStyle={{
              paddingBottom: '0.5em',
              borderBottom: '5px solid rgba(39, 110, 241, 1.0)'
            }}>
            Programs
          </Link>
        </Box>
        <Box px={[1, 3]}>
          <Link
            to='/about'
            style={{
              color: 'black',
              textDecoration: 'none'
            }}
            activeStyle={{
              paddingBottom: '0.5em',
              borderBottom: '5px solid rgba(39, 110, 241, 1.0)'
            }}>
            About
          </Link>
        </Box>
        <Box px={[1, 3]}>
          <Link
            to='/faq'
            style={{
              color: 'black',
              textDecoration: 'none'
            }}
            activeStyle={{
              paddingBottom: '0.5em',
              borderBottom: '5px solid rgba(39, 110, 241, 1.0)'
            }}>
            FAQ
          </Link>
        </Box>
        <Box px={[1, 3]}>
          <Link
            to='/contact'
            style={{
              color: 'black',
              textDecoration: 'none'
            }}
            activeStyle={{
              paddingBottom: '0.5em',
              borderBottom: '5px solid rgba(39, 110, 241, 1.0)'
            }}>
            Contact
          </Link>
        </Box>
      </Flex>
    </Container>
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
