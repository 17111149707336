import React from 'react'
import { Link } from 'gatsby'

import {
  Flex,
  Box,
  Text
} from 'rebass'

import theme from './theme'

import { Link as OutsideLink } from 'rebass'

import privacyPolicy from '../files/reversity_privacy_policy.pdf'
import termsOfService from '../files/reversity_terms_of_service.pdf'

import Container from './container'

const Footer = () => (
  <Container>
    <Flex
      p={3}
      alignItems='center'
      flexWrap='wrap'
      style={{
        textAlign: 'center'
      }}>
      <Box width={1/2} p={1}>
        <Link
          to='/about'
          style={{
            textDecoration: 'none',
            color: theme.colors.blue
          }}>
        Company
      </Link>
      </Box>
      <Box width={1/2} p={1}>
        <Link
          to='/contact'
          style={{
            textDecoration: 'none',
            color: theme.colors.blue
          }}>
          Contact
        </Link>
      </Box>

      <Box width={1/2} p={2}>
        <OutsideLink
          href='//facebook.com/reversity.io'
          target='_blank'
          rel='noopener noreferrer'>
          Facebook
        </OutsideLink>
      </Box>
      <Box width={1/2} p={1}>
        <OutsideLink
          href='//instagram.com/reversity.io'
          target='_blank'
          rel='noopener noreferrer'>
          Instagram
        </OutsideLink>
      </Box>

      <Box width={1/2} p={1}>
        <OutsideLink href={termsOfService} target='_blank' rel='noopener noreferrer'>Terms of Service</OutsideLink>
      </Box>
      <Box width={1/2} p={1}>
        <OutsideLink href={privacyPolicy} target='_blank' rel='noopener noreferrer'>Privacy Policy</OutsideLink>
      </Box>

      <Box width={1} mt={3}>
        <Text color='gray'>&#169; Reversity 2019</Text>
      </Box>
    </Flex>
  </Container>
)

export default Footer
